// Font family
$font-stack: 'Open Sans', sans-serif; // Html

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
}
// Google font ballback

// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2em;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: 30px;
  font-weight: 600;
}
.h-like-b {
  font-size: 26px;
  font-weight: 600;
}
.h-like-d {
  font-size: 22px;
  font-weight: 600;
}
.h-like-c {
  padding: 7px 0 7px 15px;
  display: inline-block;
  background-color: $ye-a;
  font-weight: 600;
  position: relative;
  font-size: 24px;
  font-style: italic;
  color: $bk-a;
  span {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: "";
    width: 30px;
    height: 100%;
    background-color: $ye-a;
    position: absolute;
    top: 0;
    right: -15px;
    z-index: 1;
    transform: skew(-30deg);
  }
  &.small {
    font-size: 16px;
  }
}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {}
}
@include media-breakpoint-down(xs) {
  .h-like-a {}
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-b { font-size: 12px; margin-bottom: 0;}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {
    font-size: 26px;
  }
  .h-like-b {
    font-size: 24px;
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 24px;
  }
  .h-like-b {
    font-size: 22px;
  }
  .h-like-c {
    font-size: 22px;
  }
}
@include media-breakpoint-down(xs) {
  .p-like-a {
    font-size: 12px;
  }
  .h-like-b {
    font-size: 20px;
  }
}
