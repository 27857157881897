// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite.back-top {
	width: 49px;
	height: 50px;
}

.sprite.back-top {
	background-position: 0 0;
}


.sprite.facebook,
.sprite.user,
.sprite.phone {
	width: 30px;
	height: 30px;
}

.sprite.facebook {
	background-position: -41px 0;
}
.sprite.user {
	background-position: -70px 0;
}
.sprite.phone {
	background-position: -100px 0;
}

.sprite.vehicle-boite-a,
.sprite.vehicle-boite-m,
.sprite.vehicle-clim,
.sprite.vehicle-motorisation-d,
.sprite.vehicle-motorisation-e,
.sprite.vehicle-passager-2,
.sprite.vehicle-passager-3,
.sprite.vehicle-passager-4,
.sprite.vehicle-passager-5,
.sprite.vehicle-passager-6,
.sprite.vehicle-passager-7,
.sprite.vehicle-passager-8,
.sprite.vehicle-passager-9,
.sprite.vehicle-porte-2,
.sprite.vehicle-porte-3,
.sprite.vehicle-porte-4,
.sprite.vehicle-porte-5,
.sprite.vehicle-radio,
.sprite.vehicle-bagage,
.sprite.vehicle-capacity {
	width: 20px;
	height: 20px;
}

.sprite.vehicle-clim {
	background-position: 0 -50px;
}

.sprite.vehicle-radio {
	background-position: -20px -50px;
}

.sprite.vehicle-passager-2 {
	background-position: -40px -50px;
}

.sprite.vehicle-passager-3 {
	background-position: -60px -50px;
}

.sprite.vehicle-passager-4 {
	background-position: -80px -50px;
}

.sprite.vehicle-passager-5 {
	background-position: -100px -50px;
}

.sprite.vehicle-passager-6 {
	background-position: -120px -50px;
}

.sprite.vehicle-passager-7 {
	background-position: -140px -50px;
}

.sprite.vehicle-passager-8 {
	background-position: -160px -50px;
}

.sprite.vehicle-passager-9 {
	background-position: -180px -50px;
}

.sprite.vehicle-porte-2 {
	background-position: -200px -50px;
}

.sprite.vehicle-porte-3 {
	background-position: -220px -50px;
}

.sprite.vehicle-porte-4 {
	background-position: -240px -50px;
}

.sprite.vehicle-porte-5 {
	background-position: -260px -50px;
}

.sprite.vehicle-boite-m {
	background-position: -280px -50px;
}

.sprite.vehicle-boite-a {
	background-position: -300px -50px;
}

.sprite.vehicle-motorisation-d {
	background-position: -320px -50px;
}

.sprite.vehicle-motorisation-e {
	background-position: -340px -50px;
}

.sprite.vehicle-bagage {
	background-position: -360px -50px;
}

.sprite.vehicle-capacity {
	background-position: -380px -50px;
}
