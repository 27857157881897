// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$ye-a: #FFC401;
$ye-b: #E0B013;
$gy-a: #eff0f5;
$gy-b: tint($gy-a, 60%);
$gy-c: #707070;

// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}
.c-ye-a {
  color: $ye-a;
}
.c-ye-b {
  color: $ye-b;
}
.c-gy-a {
  color: $ye-a;
}
.c-gy-b {
  color: $gy-b;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}
.bg-ye-a {
  background-color: $ye-a;
}
.bg-ye-b {
  background-color: $ye-b;
}
.bg-gy-a {
  background-color: $gy-a;
}
.bg-gy-b {
  background-color: $gy-b;
}
