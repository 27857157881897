.item-services {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  background-color: $wh-a;
  border: 1px solid $gy-a;
  padding: 15px 25px;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    color: $bk-a;
    padding-top: px(15);
  }
  .desc {
    line-height: 1.3em;
    font-size: px(13);
    margin-top: px(5);
    padding-bottom: px(5);
  }
  @include media-breakpoint-down(xs) {
    .title {
      font-size: px(18);
    }
  }
}
