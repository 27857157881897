// Header :: Fixed or small/large

body {
	&:not(.except-resa) {
		padding-top: 118px;
		@include media-breakpoint-down(lg) {
			padding-top: 118px;
		}
		@include media-breakpoint-down(md) {
			padding-top: 121px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 164px;
		}
		@include media-breakpoint-down(xs) {
			padding-top: 105px;
		}
	}
	&.except-resa {
		padding-top: 64px;
		@include media-breakpoint-down(lg) {
			padding-top: 52px;
		}
		@include media-breakpoint-down(md) {
			padding-top: 55px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 67px;
		}
		@include media-breakpoint-down(xs) {
			padding-top: 45px;
		}
	}
}


body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

	#nav-mobile-trigger {
		position: absolute;
		top: 60px;
		right: 15px;
	}

#header {
	position: fixed;
	z-index: 10;
	top: 0;
	width: 100%;
	width: 100%;
	padding: 0;
	border-bottom: 5px solid $ye-a;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;

	.header-top {
		background-color: $gy-a;
		padding: 3px 0;
		.list {
			text-align: right;
			li {
				display: inline-block;
				vertical-align: middle;
				&:last-child {
					margin-left: 30px;
					@include media-breakpoint-down(xs) {
						margin-left: 10px;
					}
				}
				.number {
					padding: 0 2px;
					font-size: 16px;
					@include media-breakpoint-down(xs) {
						font-size: 12px;
					}
					&:hover {
						text-decoration: underline;
					}
				}
				.social {
					&:hover {
						.sprite {
							transform: scale(.9);
						}
					}
				}
				> * {
					display: inline-block;
					vertical-align: middle;
					> * {
						display: inline-block;
						vertical-align: middle;
						@include media-breakpoint-down(xs) {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.header-main {
		padding: 10px 0;
		@include media-breakpoint-down(xs) {
			padding: 10px 0 0;
		}
	}
	.action-menu {
		li {
			display: inline-block;
			vertical-align: middle;
			.dropdown-menu {
				min-width: 0;
				.dropdown-item {
					&.active {
						background-color: $ye-a;
					}
					&:active {
						background-color: $wh-a;
						color: $bk-a;
					}
				}
			}
			button {
				span {
					font-size: 16px;
					font-weight: 600;
				}
				> * {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}
// Navigation :: Main

#nav-main {
	display: flex;

	.item {
		flex-grow: 1;
		position: relative;
	}

	.link {
		display: block;
		color: $bk-a;
		font-size: 15px;
		line-height: 1.2em;
		padding: px(20) 0;
		transition: all .2s ease-in-out;
		text-align: center;
		&:after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			opacity: 0;
			margin-left: -7.5px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7.5px 8px 7.5px;
			border-color: transparent transparent #ffc401 transparent;
			transition: all .2s ease-in-out;
		}
	}

	.link:focus,
	.link:hover {
		color: $ye-a;
	}

	.link.active {
		color: $ye-a;
		&:after {
			opacity: 1;
		}
	}

	.nav-sub {
		.item-sub {}

		.link-sub {}

		.link-sub:focus,
		.link-sub:hover {}

		.link-sub.active {}
	}
	@include media-breakpoint-down(lg) {
		.link {
			font-size: 14px;
		}
	}
	@include media-breakpoint-down(md) {
		.link {
			font-size: 13px;
			height: 60px;
			display: flex;
			align-items: center;
		}
	}
	@include media-breakpoint-down(sm) {
		.link {
			font-size: 14px;
			padding: 0 5px;
			height: 50px;
		}
	}
	@include media-breakpoint-down(xs) {
		display: none;
		text-align: center;
		background-color: $gy-a;
		padding: 15px 0;
		border-bottom: 0;

		.item {
			padding: 10px 0;
			&:after {
				content: "";
				display: block;
				width: 20px;
				margin: 10px auto 0;
				height: 4px;
				background-color: $ye-a;
			}
		}
		.link {
			display: block;
			height: auto;
			font-size: 17px;
			font-weight: 600;
			&.active {
				color: $ye-a;
			}
			&:after {
				display: none;
			}
		}
	}
}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
	background-color: $wh-a;
}

li[data-navsub] {
	position: relative;
}
@include media-breakpoint-up(sm) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: block;
		top: px(58);
		padding: px(10);
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		overflow: hidden;
		height: 0;
		width: 100%;
		padding: 0;
		opacity: 0;
		background-color: $wh-a;
		transition: all 0.1 ease-in-out;
		box-shadow: 0 3px 5px -2px rgba(0,0,0,0.2);
	}
}



// Header page
#header-page {
	color: $bk-a;
	text-align: center;
	position: relative;
	overflow: hidden;
	padding: px(75) 0;
	background-position: center 0;
	background-attachment: fixed;
	background-image: url('../images/illustrations/resa-bg-inner.jpg');

	.container {
		position: relative;
		z-index: 2;
	}
	.title {
		font-size: px(44);
		font-style: italic;
		font-weight: 700;
		line-height: 1.2em;
	}
	@include media-breakpoint-down(md) {
		padding: px(50) 0;
		&:after {
			display: none;
		}
		.title {
			font-size: px(38);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(40) 0;
		.title {
			font-size: px(34);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(25) 0;
		.btn-a {
			padding: 8px 17px;
			font-size: 11px;
		}
		.title {
			font-size: px(28);
		}
	}
}
