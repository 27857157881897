#footer {
	color: $bk-a;
	.link {
		&:hover {
			text-decoration: underline;
		}
	}
	.social {
		display: flex;
		align-items: center;
		line-height: 1.1em;
		justify-content: flex-end;
		@include media-breakpoint-down(md) {
			justify-content: flex-start;
		}
		&:hover {
			span {
				text-decoration: underline;
			}
			.sprite {
				transform: scale(.9);
			}
		}
	}
	.link-sub {
		font-size: 12px;
		&:hover {
			text-decoration: underline;
		}
	}
	.pixell {
		> * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {
	}
}
