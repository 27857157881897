// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	transition: all 0.2s ease-in-out;
	display: inline-block;
	padding: 16px 22px;
	text-transform: uppercase;
	font-weight: 700;
	border-width: 3px;
	border-style: solid;
	font-size: 11px;
	border-radius: 4px;
	&.small {
		padding: 12px 18px;
	}


	&.black {
		background-color: $bk-a;
		color: $wh-a;
		border-color: $bk-a;
		&:hover,
		&:focus {
			background-color: $wh-a;
			color: $bk-a;
		}
	}

	&.grey {
		background-color: shade($gy-a, 20%);
		color: $wh-a;
		border-color: shade($gy-a, 20%);
		&:hover,
		&:focus {
			background-color: $wh-a;
			color: shade($gy-a, 20%);
		}
	}

	&.yellow {
		background-color: $ye-a;
		border-color: $ye-a;
		color: $bk-a;
		&:hover,
		&:focus {
			background-color: $wh-a;
			color: $ye-a;
		}
	}


}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $bk-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}
