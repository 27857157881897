.form-resa-inner {
  padding: 20px 0;
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

.select-category {
  .dropdown-toggle {
    border-radius: 20px;
    background-color: shade($gy-a, 10%);
    height: 40px;
    padding: 8px 20px 0 20px;
    border: 0;
    .filter-option-inner-inner {
      text-overflow: ellipsis;
    }
  }
}

.form-resa {
		background-color: $wh-a;
		border-radius: 5px;
		padding: 30px 155px 30px 30px;
		position: relative;

		.btn-submit {
			position: absolute;
			right: 30px;
			width: 110px;
			top: 58px;
			height: 87px;
			border-radius: 3px;
			padding: 0 ;
			font-weight: 600;
			display: block;
			font-size: 13px;
			background-color: $ye-a;
			color: $bk-a;
			transition: all .2s ease-in-out;
			&:hover {
				background-color: shade($ye-a, 5%);
			}

		}
		.label-title {
			font-size: 15px;
			font-weight: 600;
		}
		.label-select {
			font-size: 11.5px;
			font-weight: 600;
			line-height: 1.2em;
			margin-bottom: 10px;
		}

		.resa-picker {
			padding: 5px 10px;
			height: 85px;
			border: 1px solid $gy-a;
			border-right: 0;
			&.last {
			 border-right: 1px solid $gy-a;
			}

			.checkboxradio-wp  {
				margin-top: 5px;
				input[type=checkbox] + label,
				> input[type=radio] + label {
					display: block;
					padding: 0 0 0 23px;
					font-size: 11.5px;
					font-weight: 300;
					color: $bk-a;
					font-weight: 400;
					background-image: url("../images/icons/sprite-checkbox_radio.png");
					line-height: 1.3em;
					background-repeat: no-repeat;
					background-size: 13px;
				}
			}

			.datepicker-wp {
				input {
					width: 100%;
					border: 0;
					outline: 0;
					font-size: 11.5px;
					cursor: pointer;
					&::placeholder {
						opacity: 1 !important;
						font-size: 10.5px;
						color: #999 !important;
					}
					&::-moz-placeholder {
						opacity: 1 !important;
						font-size: 10.5px;
						color: #999 !important;
					}
				}
				.placeholder-trick {
					cursor: pointer;
					font-size: 10.5px;
					color: #999 !important;
					position: absolute;
					top: 20px;
					left: 3px;
				}
				.ui-datepicker-trigger {
					display: none;
				}
			}

			.bootstrap-select {
				width: 100% !important;
				> .dropdown-toggle {
					white-space: normal;
					outline: none !important;
					&.bs-placeholder {
						padding-right: 25px;
						.filter-option-inner-inner {
							font-size: 10.5px;
						}
					}
					&:after {
						display: none;
					}
					.filter-option-inner-inner {
						font-size: 12px;
						line-height: 1.4em;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			padding: 20px 125px 20px 20px;
			.label-title {
				font-size: 14px;
			}
			.btn-submit {
				width: 90px;
				right: 20px;
				top: 46px;
				font-size: 12px;
			}
			.resa-picker {
				.checkboxradio-wp {
					> input[type=radio] + label {
						font-size: 10.5px;
						padding: 2px 0 0 18px;
					}
				}
				.bootstrap-select {
						> .dropdown-toggle {
							&.bs-placeholder {
							}
							.filter-option-inner-inner {
								font-size: 11px;
								line-height: 1.4em;
							}
						}
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding: 25px 70px 25px 50px;
			.resa-picker {
				.bootstrap-select {
					> .dropdown-toggle {
						&.bs-placeholder {
							padding-right: 110px;
						}
						.filter-option-inner-inner {
							font-size: 11px;
							line-height: 1.4em;
						}
					}
				}
			}
			.btn-submit {
				width: 375px;
				height: 86px;
				right: 55px;
				top: auto;
				bottom: 24px;
				font-size: 15px;
			}
		}
		@include media-breakpoint-down(sm) {
			padding: 25px 40px 25px 25px;
			.resa-picker {
				.bootstrap-select {
					> .dropdown-toggle {
						&.bs-placeholder {
							padding-right: 60px;
						}
					}
				}
			}
			.btn-submit {
				width: 280px;
				right: 25px;
			}
		}
		@include media-breakpoint-down(xs) {
			max-width: 400px;
			margin: 0 auto;
			.resa-picker{
				&.first {
					border-right: 1px solid $gy-a;
					height: auto;
					padding-bottom: 10px;
					.bootstrap-select {

					}
				}
			}
			.btn-submit {
				width: calc(50% - 35px);
				right: 25px;
			}
		}

}

#resa-header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: $wh-a;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: all .2s ease-in-out;
  padding: 5px 0;

  .logo {
    position: relative;
    display: block;
    padding: 0;
  }

  .logo .img-fluid {
    display: block;
    margin: 0 auto;
  }

  .logo:after {
    content: '';
    position: absolute;
    top: -10px;
    right: -30px;
    height: calc(100% + 27px);
    width: 1px;
    background-color: $gy-a;
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: tint($bk-a, 50%);
  }

  .item .txt {
    font-size: px(14);
    font-weight: 400;
  }

  .item .number {
    font-size: px(18);
    line-height: 1em;
    position: relative;
    top: -1px;
    font-weight: 400;
    margin-right: 5px;
    color: tint($bk-a, 50%);
  }

  .item .number:after {
    content: '.';
  }

  .item:not(.first):before {
    content: '';
    position: absolute;
    top: 50%;
    left: -15px;
    height: 14px;
    margin-top: -7px;
    width: 1px;
    background-color: $gy-a;
  }

  .item.active {
    color: $ye-a;
  }

  .item.active .number:after {
    display: none;
  }

  .item.active .number {
    background-color: $ye-a;
    margin-right: 10px;
    font-size: px(14);
    padding-top: 5px;
    color: $wh-a;
    text-align: center;
    border-radius: 25px;
    width: 25px;
    height: 25px;
  }

  .item.active .txt {
    font-weight: 700;
  }
  @include media-breakpoint-down(md) {
    padding: px(10) 0;

    .logo {
      img {
        margin: 0 !important;
      }
    }

    .logo:after {
      right: -15px;
    }

    .item .txt {
      font-size: px(15);
    }

    .item .number {
      font-size: px(16);
    }

    .item.active .number {
      font-size: px(13);
      width: 20px;
      height: 20px;
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(sm) {
    .logo:after {
      right: 5px;
    }

    .row {
      align-items: flex-start !important;
    }

    .logo .img-fluid {
      display: block;
      width: auto;
      margin: 0 auto;
    }

    .logo:after {
      right: -8px;
    }

    .item {
      flex-flow: column wrap;
      padding-top: 8px;
    }

    .item .number {
      margin-right: 0 !important;
    }

    .item .number:after {
      display: none;
    }

    .item .txt {
      font-size: px(13);
    }

    .item.active .number {
      font-size: px(11.5);
      width: 17px;
      line-height: 1.1em;
      height: 17px;
      padding-top: 2px;
    }

    .item.active .txt {
      margin-top: 3px;
    }

    .item:not(.active) .txt {
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(5) 0;

    .col-4 {
      flex: 0 0 120px;
      max-width: 120px;
    }

    .col-8 {
      flex: 0 0 calc(100% - 120px);
      max-width: calc(100% - 120px);
    }

    .row {
      align-items: center !important;
    }

    .item {
      padding-top: 0;
    }

    .item .txt {
      display: none;
    }

    .item.active .number {
      width: 20px;
      height: 20px;
      padding-top: 4px;
      font-size: px(12);
    }
  }
}

#resa-summary-wp {
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  background-image: url('../images/illustrations/resa-bg.jpg');
  background-attachment: fixed;
  background-position: top center;
  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
  @include media-breakpoint-down(xs) {
    padding: 15px 0 15px 0 !important;
  }
}

#resa-summary {
  position: relative;
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: $wh-a;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);

  .column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 41%;
    padding: 20px 15px 20px 0;

    &:not(.except):after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $gy-a;
      right: px(20);
      top: 0;
    }
  }

  .column.except {
    width: 18%;
  }

  .column.except .list {
    border-right: none;
  }

  .column:first-child .list {
    padding-left: 50px;
  }

  .column:first-child .list:after {
    left: 20px;
  }

  .list {
    padding: 0 15px 0 40px;
    position: relative;
    height: 100%;
  }

  .column .material-icons {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -10px;
    color: $bk-a;
  }

  .column.except-bis .material-icons {
    left: 5px;
    color: $bk-a;
  }

  .column.except .list:after {
    display: none;
  }

  .column.except .list {
    padding-left: 40px;
  }

  .column.except .img {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 5px;
    font-size: px(18);
  }

  .place {
    text-transform: uppercase;
    display: block;
    line-height: 1.2em;
    font-weight: 700;
    color: $bk-a;
    font-size: px(14);
  }

  .date {
    color: tint($bk-a, 50%);
    font-weight: 400;
    font-size: px(12);
    display: block;
    line-height: 1.2em;
    margin-top: 5px;
  }

  .btn-submit {
    width: 130px;
    height: 84px;
    background-color: $ye-a;
    color: $bk-a;

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

  .btn-submit > * {
    display: inline-block;
    vertical-align: middle;
  }
  @include media-breakpoint-down(lg) {
    .column:not(.except):after {
      right: 15px;
    }
  }
  @include media-breakpoint-down(md) {
    .column {
      padding: 15px 15px 15px 0;
    }

    .place {
      font-size: 12px;
    }

    .column:not(.except):after {
      right: 5px;
    }

    .btn-submit {
      height: 81px;
      width: 120px;
    }
  }
  @include media-breakpoint-down(sm) {
    .place {
      font-size: px(11);
    }

    .date {
      font-size: px(10);
      margin-top: px(2);
    }

    .column {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except .img {
      display: none !important;
    }

    .column .list {
      padding-left: 10px !important;
    }

    .column .material-icons {
      display: none !important;
    }

    .btn-submit {
      height: 70px;
      font-size: px(13);
      width: 90px;

      .material-icons {
        font-size: px(18);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .column.except .img {
      left: 8px !important;
      font-size: px(15) !important;
      display: block !important;
    }

    .column:after {
      display: none !important;
    }

    .column .material-icons {
      left: 7px !important;
      font-size: px(20) !important;
      display: block !important;
    }

    .column,
    .column .list {
      display: block;
      width: 100% !important;
    }

    .column .list {
      border-right: none !important;
      padding-left: 32px !important;
    }

    .column {
      border-bottom: 1px solid $gy-a;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except {
      border-bottom: none !important;
    }

    .list:after {
      left: 10px !important;
    }

    .column.except .list:after {
      left: 10px !important;
    }

    .btn-submit {
      height: 35px;
      width: 100%;
    }
  }
}

.resa-car-wp {
  .resa-car.except-parc .column.price-wp .bloc {
    height: 100%;
  }

  .resa-car.except-parc .column.price-wp .bloc .btn-submit {
    padding-top: 17px;
  }

  .resa-car.except-parc .column.price-wp .total:before {
    top: 3px;
  }

  .resa-car.except-parc .column.price-wp .price-total small {
    font-weight: 400;
    font-size: 11px;
  }

  .promo {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 60px;
    height: 60px;
    background-color: $ye-b;
    border-radius: 60px;
    text-transform: uppercase;
    z-index: 4;
    font-size: px(10);
    color: $wh-a;
    text-align: center;
    font-weight: 600;
    display: block;
    padding-top: 24px;
    line-height: 1em;
    transform: rotate(23deg) scale(1.2);
  }

  .resa-car.unavailable:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(255,255,255,0.5);
    width: calc(100% - 350px);
    height: 100%;
  }

  .single-col {
    height: 100%;
    padding: 30px 50px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .btn-submit {
      font-size: 14px !important;
      height: 70px !important;
    }
    @include media-breakpoint-down(xs) {
      padding: 20px;
    }
  }

  .resa-car .unavailable-date,
  .resa-car .unavailable-txt {
    color: tint($bk-a, 50%);
    line-height: 1.2em;
    text-align: center;
    font-size: px(13);
  }

  .resa-car .unavailable-date {
    font-weight: 600;
    margin-top: px(10);
    font-size: px(14);
  }

  .item {
    padding-bottom: 50px;
  }

  .resa-car {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid shade($gy-a,5%);
    overflow: hidden;
    border-radius: 10px;
    background-color: $wh-a;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);
    transition: all .2s ease-in-out;
  }

  .resa-car:focus,
  .resa-car:hover {
    box-shadow: box-shadow, 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  }

  .resa-car .column {
    padding: 30px;
  }

  .resa-car .column.img-wp {
    flex: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 325px;
    border-right: 1px solid shade($gy-a,5%);
    vertical-align: middle;
  }

  .resa-car .column.img-wp .img-fluid {
    width: 325px;
  }

  .resa-car .column.img-wp .galery-more {
    background-color: tint($gy-a,25%);
    font-size: px(24);
    width: 40px;
    height: 40px;
    padding-top: 7px;
    line-height: 1.1em;
    font-weight: 400;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    color: $ye-a;
  }

  .resa-car .column.price-wp {
    flex: none;
    text-align: center;
    width: 350px;
    background-color: $wh-a;
    border-left: 1px solid shade($gy-a,5%);
    padding: 0;
    //display: flex;
    border-radius: 0;
    @include media-breakpoint-down(lg) {
      width: 500px;

      .bloc {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .resa-car .column.price-wp .price {
    font-size: px(28);
    margin-right: 10px;
    font-weight: 600;
    color: $ye-b;
  }

  .resa-car .column.price-wp .btn-a {
    width: 200px;
  }

  .resa-car .column.price-wp .day {
    display: inline-block;
    line-height: 1em;
    font-size: px(11);
    font-style: italic;
    font-weight: 400;
    color: $gy-a;
  }

  .resa-car .column.price-wp .total {
    position: relative;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    color: $ye-a;
  }

  .resa-car .column.price-wp .bloc.right .total {
    color: shade($gy-a, 50%);
  }

  .resa-car .column.price-wp .total:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    margin-top: 0;
    // @extend .sprite;
    // @extend .arrow-select;
    transform: rotate(-90deg) scale(0.9);
  }

  .resa-car .column.price-wp .price-list {
    margin-bottom: 2px;

    .small {
      display: block;
      font-size: 11px;
      padding: 5px 10px;
      margin-top: 5px;
      line-height: 1em;
      background-color: shade($gy-a, 5%);
      border-radius: 20px;
      color: $ye-b;
    }
  }

  .label-cheap {
    border-radius: 10px 0 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $ye-b;
    color: #FFF;
    font-size: 11px;
    padding: 3px 9px;
  }

  .resa-car .column.price-wp .price-total {
    font-size: px(20);
    font-weight: 600;
  }

  .resa-car .column.price-wp .btn-submit {
    text-align: center;
    width: 100%;
    color: $wh-a;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    height: 50px;
    background-color: tint($bk-a, 30%);
    transition: all .2s ease-in-out;
    border-radius: 10px;
  }

  .resa-car .column.price-wp .btn-submit.orange {
    background-color: $ye-b;
  }

  .resa-car .column.price-wp .btn-submit .small {
    display: block;
    line-height: 1em;
    margin-top: 3px;
    text-align: center;
    font-size: px(9);
    font-style: italic;
    text-transform: none;
  }

  .resa-car .column.price-wp .btn-submit.orange:focus,
  .resa-car .column.price-wp .btn-submit.orange:hover {
    background-color: shade($ye-a, 10%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .btn-submit:focus,
  .resa-car .column.price-wp .btn-submit:hover {
    background-color: shade($bk-a, 5%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .btn-submit.green:focus,
  .resa-car .column.price-wp .btn-submit.green:hover {
    background-color: shade($bk-a, 10%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .txt-small {
    font-size: 10px;
    display: block;
    line-height: 1.2em;
    font-weight: 600;
  }

  .resa-car .column.content-wp {
    vertical-align: top;
    padding: 20px 30px;
  }

  .resa-car .column.content-wp .title {
    font-size: px(24);
    font-weight: 600;
    color: $bk-a;
    margin-bottom: 5px;
  }

  .resa-car .column.content-wp .title .similar {
    color: $bk-a;
    font-size: px(12);
    font-weight: 400;
    font-style: italic;
  }

  .resa-car .column.content-wp .category {
    display: inline-block;
    line-height: 1em;
    font-size: px(11);
    font-weight: 300;
    background-color: $gy-a;
    padding: 5px 10px;
    border-radius: 15px;
  }

  .resa-car .txt-presentation {
    font-size: px(13);
    line-height: 1.4em;
    font-weight: 300;
    margin-top: 10px;
    display: block;
  }

  .resa-car .option-wp {
    margin-top: 15px;
  }

  .resa-car .option-wp .item-sub {
    float: left;
    width: 40%;
    margin-bottom: 2px;
  }

  .resa-car .option-wp .txt {
    font-size: px(12);
  }

  .resa-car .option-wp .sprite,
  .resa-car .option-wp .txt {
    vertical-align: middle;
  }

  .resa-car .option-wp .sprite {
    margin-right: 5px;
  }

  .resa-car .price-title {
    font-size: px(12);
    font-weight: 600;
    display: block;
    line-height: 1em;
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  .resa-car .column.price-wp .bloc {
    padding: 15px 30px;
    // height: 100%;
    // width: 50%;
    // display: flex;
    // flex-flow: column wrap;
    // justify-content: space-between;
    // display: flex;
    // flex-flow: column wrap;
    // align-items: flex-start;
    // justify-content: center;
  }

  .resa-car .column.price-wp .bloc.left {
    border-bottom: 1px solid shade($gy-a,5%);
  }
  @include media-breakpoint-down(md) {
    .resa-car .column.price-wp .bloc.left {
      border-bottom: 0;
    }
    .item {
      padding-bottom: 40px;
    }

    .resa-car .column.price-wp .price-list .small {
      display: inline-block;
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      display: inline-block;
      margin: 0;
    }

    .resa-car.unavailable {
      padding-bottom: 94px;
    }

    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 93px);
    }

    .resa-car.unavailable .column.price-wp .bloc.left {
      justify-content: center;
    }

    .resa-car {
      padding-bottom: 141px;
    }

    .resa-car .column.img-wp {
      width: 270px;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 270px;
    }

    .resa-car .column.price-wp {
      background-color: tint($gy-a, 50%);
      height: auto !important;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      padding: 0;
      width: 100%;
      flex-flow: row nowrap;
      border-top: 1px solid shade($gy-a,5%);
    }

    .resa-car .column.price-wp .bloc {
      width: 50%;
      height: 140px;
    }

    .resa-car .column.price-wp .bloc.left {
      order: 1;
      border-left: 1px solid shade($gy-a,5%);
    }

    .resa-car.except-parc {
      padding-bottom: 131px;
    }

    .resa-car.except-parc .column.price-wp .bloc {
      width: 100%;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .resa-car.except-parc .column.price-wp .total br {
      display: none;
    }

    .resa-car.except-parc .column.price-wp .btn-submit {
      width: 275px;
    }

    .resa-car.except-parc .column.price-wp .total:before {
      top: 10px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .resa-car.unavailable {
      padding-bottom: 0;
    }

    .resa-car.unavailable:after {
      height: calc(100% - 90px) !important;
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      text-align: left;
    }

    .promo {
      top: 5px;
      right: 5px;
      transform: rotate(0) scale(.75);
    }

    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 74px);
    }

    .resa-car-wp .resa-car .column.content-wp {
      padding-top: 10px;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 70%;
      margin: 20px auto;
      display: block;
    }

    .item {
      padding-bottom: 20px;
    }

    .resa-car {
      display: block;
      padding-bottom: 0;
    }

    .resa-car .column.img-wp {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .resa-car .column {
      display: block;
      width: 100% !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      border-right: none !important;
    }

    .resa-car .column.content-wp {
      border-top: 1px solid shade($gy-a, 10%);
      border-bottom: 1px solid shade($gy-a, 10%);
    }

    .resa-car .column.price-wp {
      position: relative;
      bottom: auto;
      left: auto;
      padding: 0 !important;
      border-top: none !important;
    }

    .resa-car .column.price-wp .btn-submit,
    .resa-car .column.price-wp .price-list {
      display: block;
      float: none;
      width: 100%;
      margin-top: 0;
      border: none !important;
    }

    .resa-car .column.price-wp .price-list {
      padding: 0;
      margin-top: 5px;
    }

    .resa-car .column.price-wp .btn-submit {
      margin-bottom: 0;
    }

    .resa-car .column.price-wp .bloc {
      padding: 10px 20px 20px;
    }

    .resa-car.except-parc {
      padding-bottom: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 91px);
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      text-align: center;
    }

    .resa-car .unavailable-date {
      margin-top: px(10);
    }

    .resa-car .column.content-wp .title {
      font-size: px(22);
    }

    .resa-car .column.img-wp {
      width: 100%;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 100%;
      margin: 10px auto;
    }

    .resa-car .column.price-wp {
      width: 100%;
      flex-flow: column wrap;
    }

    .resa-car .column.price-wp .bloc {
      width: 100%;
      height: auto;
    }

    .resa-car .column.price-wp .bloc.left {
      order: 0;
      border-bottom: 1px solid shade($gy-a,5%);
      border-left: none;
    }

    .resa-car .column.price-wp .bloc {
      padding: 7px 20px 15px;
    }

    .resa-car.except-parc .column.price-wp .bloc {
      width: 100%;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: center;
    }

    .resa-car.except-parc .column.price-wp .btn-submit {
      width: 100%;
    }
  }
}

// Step 2
.resa-step-2 {
  .item {
    border-bottom: 1px solid shade($gy-a,10%);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    color: $ye-b;
    height: 75px;
    font-size: px(14);
    margin-bottom: 10px;
    display: table;
    background-color: $gy-a;

    &.price-off {
      @include media-breakpoint-down(xs) {
        padding-bottom: 8px !important;

        &:after {
          display: none !important;
        }
      }
    }

    &.total {
      margin-top: px(30);
      background-color: $bk-a;
      padding: px(8);
      color: $ye-a;

      &:after {
        display: none !important;
      }

      .title {
        color: $wh-a;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: px(10);
      }

      .col-option-2 {
        border-radius: 5px;
        border: none;
        font-size: px(20);
        background-color: $wh-a;
        font-weight: 700;

        &:before {
          display: none;
        }
      }
      @include media-breakpoint-down(xs) {
        margin-top: px(20);

        .col-option-1 {
          padding-left: 0 !important;

          .title {
            font-size: px(15);
          }
        }

        .col-option-2 {
          width: 110px !important;
          height: 57px !important;
          padding-top: px(0);

          .price {
            font-size: 18px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .more-info-trigger {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 2;
      margin-top: -9px;
      color: $bk-a;

      .material-icons {
        font-size: px(18);
      }
    }

    .col-option-1,
    .col-option-2 {
      display: table-cell;
      padding: 0 15px;
      vertical-align: middle;
    }

    .col-option-1 {
      padding: 8px 35px 8px 8px;
      font-weight: 700;
    }

    .checkboxradio-wp {
      display: table;
      width: 100%;

      > input[type=checkbox] + label {
        font-size: px(14.5);
        height: 56px;
        width: 100%;
        display: table-cell;
        color: shade($gy-a, 70%);
        line-height: 1.15em;
        background-image: url('../images/icons/sprite-checkbox_radio-step2_3.png');
        margin-bottom: 0;
        vertical-align: middle;
        padding: 0 0 0 70px;
        background-size: 56px;
        em {
          display: block;
          font-weight: 300;
          font-size: 85%;
          color: shade($gy-a, 70%);
          margin-top: px(3);
        }
        @include media-breakpoint-down(lg) {
          font-size: px(13);
        }
        @include media-breakpoint-down(sm) {
          font-size: px(15);
        }
        @include media-breakpoint-down(xs) {
          font-size: px(14);
        }
      }

      > input[type=checkbox] + label {
        background-position: 0 bottom;
      }

      > input[type=checkbox]:checked + label {
        background-position: 0 top;
        font-weight: 600;
      }
    }
  }

  .quantite, .choix {
    padding: 0 0 0 70px;

    .selectpicker-wp {
      display: inline-block;
      position: relative;

      /*.bootstrap-select .btn.dropdown-toggle {
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }*/

      .bootstrap-select .btn.dropdown-toggle {
        line-height: 1em;
        display: block !important;
        height: 25px;
        width: 56px;
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;
        &:after {
          right: 2px;
        }

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }



    }
    .label {
      color: $gy-c;
      font-weight: normal;
      padding-right: 10px;
    }
    &.disabled .selectpicker-wp .bootstrap-select .btn.dropdown-toggle,
    &.disabled .label {
      opacity: 0.4;
    }
  }


  /*.selectpicker-wp {
    position: absolute;
    top: 9px;
    left: 8px;
    display: block;
    height: 56px;
    width: 56px;
    vertical-align: middle;
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 100%;
    }
    &.inactive {
      display: none;
    }

    .bootstrap-select .btn.dropdown-toggle {
      line-height: 1em;
      display: block !important;
      height: 56px;
      width: 56px;
      border-radius: 6px;
      padding: 22px 20px 10px 10px !important;
      border: $wh-a !important;
      background-color: $wh-a;
      &:after {
        right: 6px;
      }

      .filter-option-inner-inner {
        font-size: px(16);
        font-weight: 500;
        text-align: center;
      }
    }
  }*/
}

.resa-included {
  border-bottom: 1px solid shade($gy-a, 10%);
  padding: 15px;
  background-color: $gy-a;
  border-radius: 8px;

  .item {
    line-height: 1.2em;
    position: relative;
    padding: px(3) 0 0 px(25);
    margin-bottom: px(5);
    font-size: px(14);
    font-weight: 600;
    color: $ye-b;
  }

  .item .material-icons {
    content: '';
    position: absolute;
    color: $ye-b;
    top: px(3);
    left: 0;
    font-size: px(18);
  }
}

// Step 3
.step-3-input {
  background-color: tint($gy-a, 20%);
  padding: px(10) px(15);
  border-radius: 8px;
  border-bottom: 1px solid  shade($gy-a, 10%);

  .dropdown-toggle {
    border: 0 !important;
    .filter-option-inner-inner {
      text-overflow: ellipsis !important;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: px(11);
    color: $bk-a;
  }

  input,
  textarea {
    outline: none !important;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, .05);
    border-radius: 2px;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.btn-payment {
  position: relative;
  background-color: $ye-a;
  color: $wh-a;
  font-weight: 600;
  border-radius: 10px;
  overflow: hidden;
  text-transform: uppercase;
  padding: px(10) px(20) px(10) px(75);

  .icon-left {
    position: absolute;
    height: 100%;
    width: 50px;
    padding-top: px(12);
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
  }

  .icon-right,
  .txt {
    display: inline-block;
    vertical-align: middle;
  }

  .icon-right {
    position: relative;
    top: 3px;
  }
}

.title-wp {
  position: relative;
  display: inline-block;
  padding-right: 30px;

  .more-info-trigger {
    top: px(4);
    right: 0;
    position: absolute;

    .material-icons {
      font-size: px(18);
    }
  }
}

// step 4
.resa-step-4 {
  position: relative;
  height: 100%;
  padding: 25px 30px;
  background-color: $wh-a;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;

  .title-sub {
    color: $ye-b;
    text-transform: uppercase;
    font-size: px(16);
    font-weight: 600;
    border-bottom: 1px solid $ye-b;
    padding-bottom: 5px;
  }

  .list {
    margin-top: 20px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 18px;
    font-size: px(14);
    line-height: 1.4em;
    word-break: break-word;
  }

  .list li:not(:last-child) {
    margin-bottom: 7px;
  }

  .list li:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: $ye-b;
  }
}

.resa-aside-wp {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-right: 0;
  background-color: $wh-a;

  .header {
    background-color: shade($gy-a, 50%);
    position: relative;
    color: $wh-a;
    padding: px(13) px(70) px(13) px(15);

    .category {
      text-transform: uppercase;
      font-size: px(10);
      letter-spacing: .1em;
      font-weight: 300;
    }

    .title {
      margin-top: px(3);
      font-size: px(17);
      font-weight: 400;
      line-height: 1.1em;

      em {
        font-size: 80%;
        font-weight: 300;
      }
    }

    .btn-edition {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: px(55);
      background-color: rgba(0,0,0,0.15);
      color: $wh-a;
      padding-top: px(7);

      .material-icons {
        font-size: px(26);
      }
    }
  }

  .car-wp {
    padding-bottom: px(15);
    text-align: center;

    .icons-wp {
      padding: 0 px(20);
      text-align: left;

      .txt {
        font-size: px(10);
        display: block;
        line-height: 1.1em;
      }

      .car-icon {
        position: relative;
        padding: px(5) 0 px(5) px(17);

        .sprite {
          position: absolute;
          top: px(-1);
          left: px(-5);
        }
      }
    }
  }

  .options-wp {
    padding: px(15) px(20) px(10);
    background-color: $gy-a;

    .item {
      position: relative;
      padding: px(9) px(80) px(9) 0;
      font-size: px(13);
      color: shade($gy-a, 70%);
      line-height: 1.2em;

      &.arrival-departure {
        border-radius: 5px;
        border: 1px solid shade($gy-a, 20%);
        background-color: $wh-a;
        position: relative;
        padding-left: 32px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 1.4em;
        strong {
          font-weight: 700;

        }
        .material-icons {
          position: absolute;
          left: 5px;
          top: 50%;
          color: tint($bk-a, 50%);
          margin-top: -12px;
        }
      }

      .price {
        position: absolute;
        top: px(9);
        right: 0;
        font-weight: 700;
      }

      &.promo {
        position: relative;
        padding: 5px;
        background-color: rgba(0,0,0,0.05);
        border: 1px solid shade($gy-a, 75%);
        margin-bottom: px(10);
        line-height: 1.2em;

        .btn-promo {
          text-align: left;
          position: relative;
          display: block;
          width: 100%;
          font-style: italic;
          font-size: px(12);
          padding-left: px(20);

          .material-icons {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -8px;
            font-size: px(15);
          }
        }
      }

      &.except {
        font-weight: 700;
        text-transform: uppercase;
        &.no-border {
          border-top: none !important;
        }

        .price {
          font-weight: 700;
        }
      }
    }

    .item:not(:first-child) {
      border-top: 1px solid shade($gy-a, 20%);
    }

    &.step-3 {
      .price {
        font-weight: 400 !important;
        text-decoration: underline;
        text-transform: none;
        font-size: px(11);
      }

      .item:not(.except) {
        padding-right: 0;

        em {
          font-size: 95%;
          font-style: italic;
        }
      }
    }

    .txt-info {
      padding: px(9) 0 0;
      font-style: italic;
      border-top: 1px solid shade($gy-a, 20%);
      line-height: 1.2em;
      color: shade($gy-a, 65%);
      font-size: px(12);
    }
  }

  .btn-wp {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: px(13);
    padding: px(13);
    font-weight: 600;
    background-color: $bk-a;
    color: $wh-a;
    text-align: center;
    letter-spacing: .05em;

    .material-icons {
      font-size: 15px;
    }
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  @include media-breakpoint-down(md) {
    .header {
      padding-top: px(9);
      padding-bottom: px(9);

      .title {
        margin-top: 0;
      }
    }

    .content-wp {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border: 1px solid $gy-a;
      border-radius: 0 0 8px 8px;

      .car-wp {
        flex: 0 0 45%;
        max-width: 45%;
        padding: 0 px(15) px(10);
      }

      .options-wp {
        flex: 0 0 55%;
        max-width: 55%;
        position: relative;
        z-index: 2;
        padding: px(10) px(25);

        .item {
          .price {
            top: px(9);
          }

        }
      }

      &:after {
        content: '';
        width: 55%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $gy-a;
      }
    }

    .btn-wp {
      display: block;
      width: 240px;
      margin: px(15) auto 0;
      border-radius: 8px;
      padding-top: px(8);
      padding-bottom: px(8);
      font-size: px(14);
    }
  }
  @include media-breakpoint-down(xs) {
    .content-wp {
      display: block;

      .car-wp,
      .options-wp {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      &:after {
        display: none;
      }
    }
  }
}

.btn-cgv {
  width: 100%;
  color: $bk-a;
  text-align: center;
  display: block;
  padding: .71429rem 0;
  font-size: .85714rem;
  text-decoration: underline;
}
