
.blog-article {
	height: 100%;
	.link {
		height: 100%;
		border: 1px solid $gy-a;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		flex-flow: column nowrap;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0);
		transition: all .2s ease-in-out;
		.title {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 10px;
		}
		.txt {
			font-size: 13px;
		}
		&:hover {
			box-shadow: 0 0 15px rgba(0, 0, 0, .1);
			.img-wp {
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	.content {
		padding: 15px 20px 0;
	}
	.col-bottom {
		padding: 0 20px 15px 20px;
		color: $ye-b;
	}
	.img-wp {
		display: block;
		overflow: hidden;
		border-bottom: 5px solid $ye-a;
		img {
			transition: all .2s ease-in-out;
		}
	}
}

 .nav-category {
   border: 1px solid $gy-a;
   padding: px(20) px(30);
   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
   background-color: $wh-a;
   border-radius: 8px;

   .title-cat {
     font-size: px(13);
     padding-bottom: 6px;
     color: $ye-b;
     margin-bottom: px(15);
     text-transform: uppercase;
     font-weight: 600;
     border-bottom: 1px solid shade($gy-a, 50%);
   }

   .item {
     margin-top: px(5);
   }

   .list .item:first-child {
     margin-top: 0;
   }

   .link {
     position: relative;
     font-weight: 400;
     font-size: px(14);
     color: shade($gy-a, 50%);
     display: block;
     padding-left: px(20);
   }

   .link:before {
     content: "";
     display: block;
     position: absolute;
     top: 10px;
     left: 0;
     width: 8px;
     height: 2px;
     background-color: shade($gy-a, 50%);
     transition: all 0.2s ease-in-out;
   }

   .link.active {
     color: $ye-b !important;
     font-weight: 600;
   }

   .link.active:before {
     border-color: $ye-b !important;
     background-color: $ye-b !important;
   }

   .link:focus,
   .link:hover {
     color: $ye-a;
   }

   .link:focus:before,
   .link:hover:before {
     background-color: $ye-b;
   }
   @include media-breakpoint-down(sm) {
     border: none;
     box-shadow: 0 0 20px rgba(0,0,0,0.1);
     padding-top: 20px;
     padding-bottom: 20px;
   }
 }

 .category-label {
   display: inline-block;
   color: $wh-a;
   background-color: transparent;
   font-weight: 600;
   border: 1px solid $wh-a;
   letter-spacing: 0.05em;
   margin-bottom: px(20);
   font-size: px(10);
   padding: 2px 6px 2px;
   border-radius: 3px;
   @include media-breakpoint-down(sm) {
     margin-bottom: px(10);
   }
 }

 .pagination {
 	justify-content: center;
 	margin-top: 0;

 	.page-link {
		color: tint($bk-a, 40%);
 		height: 35px;
 	}

 	.page-link.active {
 		background-color: $ye-a !important;
 		color: $wh-a !important;
 		border-top-color: $ye-a;
 		border-bottom-color: $ye-a;
 		font-weight: 600;
 	}

 	.page-item.link-arrow .page-link {
 		padding: px(6) px(12);
 		background-color: tint($gy-a, 30%);
 		color: tint($bk-a, 40%);
 		transition: all 0.2s ease-in-out;
 	}

 	.page-item.link-arrow .page-link:focus,
 	.page-item.link-arrow .page-link:hover {
 		background-color: $ye-a;
 		color: $wh-a;
 	}

 	.page-item .page-link:focus,
 	.page-item .page-link:hover {
 		background-color: $gy-b;
 	}
 }
