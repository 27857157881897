.form-resa-home {
	background-image: url('../images/illustrations/resa-bg.jpg');
	background-position: center 0;
	background-attachment: fixed;
	position: relative;
	z-index: 0;
	padding: 125px 0;
  .form-resa {
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    border-bottom: 5px solid $ye-a;
  }
	@include media-breakpoint-down(lg) {
		padding: 80px 0;
	}
	@include media-breakpoint-down(md) {
		background-attachment: inherit;
		background-size: cover;
		padding: 50px 0;
	}
	@include media-breakpoint-down(xs) {
		padding: 30px 15px;
	}
}
